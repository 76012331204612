import React, {
    createContext,
    useEffect,
    useReducer
  } from 'react';
  import type { FC, ReactNode } from 'react';
  import jwtDecode from 'jwt-decode';
  import type { User } from 'src/types/user';
  import SplashScreen from 'src/components/SplashScreen';
  import axios from 'axios';
import { apiUrl } from 'src/config';
  
  interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: User | null;
    jwt: any | null;
  }
  
  interface AuthContextValue extends AuthState {
    method: 'Strapi',
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
  }
  
  interface AuthProviderProps {
    children: ReactNode;
  }
  
  type InitialiseAction = {
    type: 'INITIALISE';
    payload: {
      isAuthenticated: boolean;
      user: User;
      jwt: any ;
    };
  };
  
  type LoginAction = {
    type: 'LOGIN';
    payload: {
      user: User;
      jwt: any;
    };
  };
  
  type LogoutAction = {
    type: 'LOGOUT';
    payload: {
      user: null;
      jwt: null;
    };
  };
  
  
  type Action =
    | InitialiseAction
    | LoginAction
    | LogoutAction;
  
  const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    jwt: null
  };
  
  const isValidToken = (accessToken: string): boolean => {
    if (!accessToken) {
      return false;
    }
  
    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
  
    return decoded.exp > currentTime;
  };
  
  const setSession = (accessToken: string | null): void => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };
  
  const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
      case 'INITIALISE': {
        const { isAuthenticated, user, jwt } = action.payload;
        if (isAuthenticated) {
          setSession(jwt);
          return {
            ...state,
            isAuthenticated: true,
            isInitialised: true,
            user,
            jwt
          };
        } 

        //setSession(null);
        return {
          ...state,
          isAuthenticated: false,
          isInitialised: true,
          user
        };
      }
      case 'LOGIN': {
        const { user, jwt } = action.payload;
        setSession(jwt);
        return {
          ...state,
          isAuthenticated: true,
          user,
          jwt
        };
      }
      case 'LOGOUT': {
        axios.interceptors.request.use(function (config) {
          //console.log("Authorization Header: ", config.headers.Authorization);
          delete config.headers.Authorization;
          return config;
        });
        setSession(null);
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          jwt: null
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  
  const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'Strapi',
    login: () => Promise.resolve(),
    logout: () => { },
  });
  
  export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
  
    const login = async (identifier: string, password: string) => {
      //console.log("logging in");
      let response;
      response = await axios.post<{ jwt: string; user: User }>(apiUrl + '/auth/local/', { identifier, password });
      console.log(response);
      const { user, jwt } = response.data;
      setSession(jwt);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          jwt
        }
      });

      // Add a request interceptor
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization =  `Bearer ${jwt}`;
        return config;
      });
    };
  
    const logout = () => {
      
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
      //console.log("Authorization Header: ", config.headers.Authorization);
      delete config.headers.Authorization;
      return config;
    });
      setSession(null);
      dispatch({
         type: 'LOGOUT',
         payload: {
            user: null,
            jwt: null
          } 
        });
    };
  
    useEffect(() => {
      const initialise = async () => {
        try {
          const accessToken = window.localStorage.getItem('accessToken');
          //console.log("checking access token")
  
          if (accessToken && isValidToken(accessToken)) {
            //console.log("access token valid, fetching user info")
            //setSession(accessToken);
            const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
            };
            const response = await axios.get(apiUrl + '/users/me', config);
            //console.log(response);
            const user = response.data;

            // Add a request interceptor
            axios.interceptors.request.use(function (config) {
              config.headers.Authorization =  `Bearer ${accessToken}`;
              return config;
            });

            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: user,
                jwt: accessToken
              }
            });
          } else {
            //console.log("access token invalid")
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null,
                jwt: null
              }
            });
          }
        } catch (err) {
          //console.log("unable to read accessToken")
          console.error(err);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              jwt: null
            }
          });
        }
      };
  
      initialise();
    }, []);
  
    if (!state.isInitialised) {
      return <SplashScreen />;
    }
  
    return (
      <AuthContext.Provider
        value={{
          ...state,
          method: 'Strapi',
          login,
          logout
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthContext;