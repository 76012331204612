import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  
  if (isAuthenticated) {
    console.log(user.role);
    switch (user.role.name) {
      case 'Auditor': 
        return <Redirect to="/app/management/donations" />;
      case 'Senior Admin':
      case 'Administrator':
      case 'Standard':
        return <Redirect to="/app/reports/dashboard" />;
      default:
        return <Redirect to="/app/reports/dashboard" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
