export const UserRoles = ['Senior Admin', 'Administrator', 'Standard', 'Auditor', 'Receipts']

// Read only
// Auditor
export const ReadOnlyRole = ['Auditor', 'Standard'];

// Create and read only
// Standard - create only donations but NOT view donor information
export const DefaultRole = ['Standard'];

// Create, read, and update only
// Administrator - all access except for deleting donations or donors
export const ReadWriteRole = ['Administrator', 'Senior Admin'];

// Create, read, update, and delete only
// Senior Admin - full access
export const FullAccessRole = ['Senior Admin'];