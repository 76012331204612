export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const apiUrl = process.env.NODE_ENV === "development" ? `http://localhost:1337` : `https://api.liarelieftrust.org`;

export const gaMeasurementId =  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? undefined : "G-38V1FFN6QT";

export const searchDonationsBy = ['donationType', 'paymentType', 'amount', 'donor.FirstName', 'donor.Surname', 'donor.Title', 'donationId'];

export const searchDonorsBy = ['Email', 'FirstName', 'Surname', 'AddressLine1', 'AddressLine2', 'City', 'Country', 'Postcode', 'Telephone', 'Mobile'];

export const defaultPaginationOptions = [5, 10, 25, 50, 100, 250];