import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import * as Sentry from "@sentry/react";
import ErrorView from './views/errors/ErrorView';

enableES5();
Sentry.init({ dsn: "https://34c6ee96bc604ec9b499c03706df5fca@o552419.ingest.sentry.io/5681700" });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<ErrorView />} showDialog>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.register();
