/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { User } from 'src/types/user';
import { UserRoles } from 'src/types/userroles';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  requiredRoles?: string[];
}

interface Section {
  items: Item[];
  subheader: string;
  requiredRoles?: string[];
}

const sections: Section[] = [
  {
    subheader: 'Reports',
    requiredRoles: [UserRoles[0], UserRoles[1]],
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
    ]
  },
  {
    subheader: 'Management',
    requiredRoles: [UserRoles[0], UserRoles[1], UserRoles[2]],
    items: [
      {
        title: 'Donors',
        icon: UsersIcon,
        requiredRoles: [UserRoles[0], UserRoles[1], UserRoles[2]],
        href: '/app/management/donors'
      },
      {
        title: 'Donations',
        icon: DollarSignIcon,
        requiredRoles: [UserRoles[0], UserRoles[1], UserRoles[2]],
        href: '/app/management/donations'
      },
      {
        title: 'Receipts',
        icon: ReceiptIcon,
        requiredRoles: [UserRoles[0], UserRoles[1], UserRoles[2]],
        href: '/app/management/receipts'
      },
      {
        title: 'Projects',
        requiredRoles: [UserRoles[0], UserRoles[1], UserRoles[2]],
        icon: BriefcaseIcon,
        href: '/app/management/projects'
      }
    ]
  },
  {
    subheader: 'Auditor',
    requiredRoles: [UserRoles[3]],
    items: [
      {
        title: 'Donations',
        icon: DollarSignIcon,
        requiredRoles: [UserRoles[3]],
        href: '/app/management/donations'
      },
    ]
  },
  {
    subheader: 'Audit Log',
    requiredRoles: [UserRoles[0]],
    items: [
      {
        title: 'Audit Log',
        icon: DollarSignIcon,
        requiredRoles: [UserRoles[0]],
        href: '/app/management/audit'
      },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  user
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  user: User;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, user }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  user
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  user: User;
}) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          user: user
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        <Box p={2}>
          <Box
            mt={2}
            textAlign="center"
          >
            <Typography
              variant="body1"
              color="textPrimary"
            >
              {user.email}
              </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your Role:
              {' '}
              {user.role.name}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => {
            if (section.requiredRoles.includes(user.role.name.toString())) {
              return <List
                key={section.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {section.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  user: user
                })}
              </List>
            }
          })}
        </Box>
      </PerfectScrollbar>
      <Box p={2}>
          <Link 
          href="mailto:contact@element-software.co.uk?subject=[ISSUE] LIA RT Receipting System"
          target="_blank"
          variant="subtitle1"
          color="textPrimary"
          style={{textDecoration: 'underline', textAlign: 'center', width: '100%' ,display: 'block', margin: '0 auto'}}
          >Need help?</Link>
          <br />
          <Link 
          href="https://element-software.co.uk"
          target="_blank"
          variant="subtitle1"
          color="textPrimary"
          style={{textDecoration: 'underline', textAlign: 'center', width: '100%', display: 'block', margin: '0 auto'}}
          >Powered by
          <img style={{maxWidth: '75px', display: 'block', width: '100%', margin: '0 auto'}} src="https://element-software.co.uk/wp-content/uploads/2020/03/ES-Logo-V1-Trimmed.png" />
          </Link>
      </Box>
    </Box>
  );

  return (
    <>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
